import { SKU } from './SkuEnums';

const selfServeSkuToDisplayNames = {
	[SKU.B2B_SELFSERVE_1Y]: 'B2B Self-serve 1 Year',
};

const premiumSkuToDisplayNames = {
	[SKU.PREMIUM_CUSTOM]: 'Premium Custom',
	[SKU.PREMIUM_1M]: 'Premium 1 Month',
	[SKU.PREMIUM_1M_RENEWABLE]: 'Premium 1 Month Renewable',
	[SKU.PREMIUM_1M_AUTORENEW]: 'Premium 1 Month Auto-renew',
	[SKU.PREMIUM_3M]: 'Premium 3 Month',
	[SKU.PREMIUM_3M_RENEWABLE]: 'Premium 3 Month Renewable',
	[SKU.PREMIUM_3M_AUTORENEW]: 'Premium 3 Month Auto-renew',
	[SKU.PREMIUM_6M]: 'Premium 6 Month',
	[SKU.PREMIUM_6M_RENEWABLE]: 'Premium 6 Month Renewable',
	[SKU.PREMIUM_6M_AUTORENEW]: 'Premium 6 Month Auto-renew',
	[SKU.PREMIUM_1Y]: 'Premium 1 Year',
	[SKU.PREMIUM_1Y_RENEWABLE]: 'Premium 1 Year Renewable',
	[SKU.PREMIUM_1Y_AUTORENEW]: 'Premium 1 Year Auto-renew',
	[SKU.PREMIUM_LIFETIME]: 'Premium Lifetime',
};

const c4bPayorBaseSkuToDisplayNames = {
	[SKU.C4B_PAYOR_BASE_CUSTOM]: 'Calm for Business Payor Base Custom',
	[SKU.C4B_PAYOR_BASE_1M]: 'Calm for Business Payor Base 1 Month',
	[SKU.C4B_PAYOR_BASE_1M_RENEWABLE]: 'Calm for Business Payor Base 1 Month Renewable',
	[SKU.C4B_PAYOR_BASE_1M_AUTORENEW]: 'Calm for Business Payor Base 1 Month Auto-renew',
	[SKU.C4B_PAYOR_BASE_3M]: 'Calm for Business Payor Base 3 Month',
	[SKU.C4B_PAYOR_BASE_3M_RENEWABLE]: 'Calm for Business Payor Base 3 Month Renewable',
	[SKU.C4B_PAYOR_BASE_3M_AUTORENEW]: 'Calm for Business Payor Base 3 Month Auto-renew',
	[SKU.C4B_PAYOR_BASE_6M]: 'Calm for Business Payor Base 6 Month',
	[SKU.C4B_PAYOR_BASE_6M_RENEWABLE]: 'Calm for Business Payor Base 6 Month Renewable',
	[SKU.C4B_PAYOR_BASE_6M_AUTORENEW]: 'Calm for Business Payor Base 6 Month Auto-renew',
	[SKU.C4B_PAYOR_BASE_1Y]: 'Calm for Business Payor Base 1 Year',
	[SKU.C4B_PAYOR_BASE_1Y_RENEWABLE]: 'Calm for Business Payor Base 1 Year Renewable',
	[SKU.C4B_PAYOR_BASE_1Y_AUTORENEW]: 'Calm for Business Payor Base 1 Year Auto-renew',
	[SKU.C4B_PAYOR_BASE_LIFETIME]: 'Calm for Business Payor Base Lifetime',
};

const c4bPayorBuyupSkuToDisplayNames = {
	[SKU.C4B_PAYOR_BUYUP_CUSTOM]: 'Calm for Business Payor Buy-up Custom',
	[SKU.C4B_PAYOR_BUYUP_1M]: 'Calm for Business Payor Buy-up 1 Month',
	[SKU.C4B_PAYOR_BUYUP_1M_RENEWABLE]: 'Calm for Business Payor Buy-up 1 Month Renewable',
	[SKU.C4B_PAYOR_BUYUP_1M_AUTORENEW]: 'Calm for Business Payor Buy-up 1 Month Auto-renew',
	[SKU.C4B_PAYOR_BUYUP_3M]: 'Calm for Business Payor Buy-up 3 Month',
	[SKU.C4B_PAYOR_BUYUP_3M_RENEWABLE]: 'Calm for Business Payor Buy-up 3 Month Renewable',
	[SKU.C4B_PAYOR_BUYUP_3M_AUTORENEW]: 'Calm for Business Payor Buy-up 3 Month Auto-renew',
	[SKU.C4B_PAYOR_BUYUP_6M]: 'Calm for Business Payor Buy-up 6 Month',
	[SKU.C4B_PAYOR_BUYUP_6M_RENEWABLE]: 'Calm for Business Payor Buy-up 6 Month Renewable',
	[SKU.C4B_PAYOR_BUYUP_6M_AUTORENEW]: 'Calm for Business Payor Buy-up 6 Month Auto-renew',
	[SKU.C4B_PAYOR_BUYUP_1Y]: 'Calm for Business Payor Buy-up 1 Year',
	[SKU.C4B_PAYOR_BUYUP_1Y_RENEWABLE]: 'Calm for Business Payor Buy-up 1 Year Renewable',
	[SKU.C4B_PAYOR_BUYUP_1Y_AUTORENEW]: 'Calm for Business Payor Buy-up 1 Year Auto-renew',
	[SKU.C4B_PAYOR_BUYUP_LIFETIME]: 'Calm for Business Payor Buy-up Lifetime',
};

const chDirectSkuToDisplayNames = {
	[SKU.CALM_HEALTH_CUSTOM]: 'Calm Health Custom',
	[SKU.CALM_HEALTH_1M]: 'Calm Health 1 Month',
	[SKU.CALM_HEALTH_1M_RENEWABLE]: 'Calm Health 1 Month Renewable',
	[SKU.CALM_HEALTH_1M_AUTORENEW]: 'Calm Health 1 Month Auto-renew',
	[SKU.CALM_HEALTH_3M]: 'Calm Health 3 Month',
	[SKU.CALM_HEALTH_3M_RENEWABLE]: 'Calm Health 3 Month Renewable',
	[SKU.CALM_HEALTH_3M_AUTORENEW]: 'Calm Health 3 Month Auto-renew',
	[SKU.CALM_HEALTH_6M]: 'Calm Health 6 Month',
	[SKU.CALM_HEALTH_6M_RENEWABLE]: 'Calm Health 6 Month Renewable',
	[SKU.CALM_HEALTH_6M_AUTORENEW]: 'Calm Health 6 Month Auto-renew',
	[SKU.CALM_HEALTH_1Y]: 'Calm Health 1 Year',
	[SKU.CALM_HEALTH_1Y_RENEWABLE]: 'Calm Health 1 Year Renewable',
	[SKU.CALM_HEALTH_1Y_AUTORENEW]: 'Calm Health 1 Year Auto-renew',
	[SKU.CALM_HEALTH_LIFETIME]: 'Calm Health Lifetime',
};

const chPayorBaseSkuToDisplayNames = {
	[SKU.CH_PAYOR_BASE_CUSTOM]: 'Calm Health Payor Base Custom',
	[SKU.CH_PAYOR_BASE_1M]: 'Calm Health Payor Base 1 Month',
	[SKU.CH_PAYOR_BASE_1M_RENEWABLE]: 'Calm Health Payor Base 1 Month Renewable',
	[SKU.CH_PAYOR_BASE_1M_AUTORENEW]: 'Calm Health Payor Base 1 Month Auto-renew',
	[SKU.CH_PAYOR_BASE_3M]: 'Calm Health Payor Base 3 Month',
	[SKU.CH_PAYOR_BASE_3M_RENEWABLE]: 'Calm Health Payor Base 3 Month Renewable',
	[SKU.CH_PAYOR_BASE_3M_AUTORENEW]: 'Calm Health Payor Base 3 Month Auto-renew',
	[SKU.CH_PAYOR_BASE_6M]: 'Calm Health Payor Base 6 Month',
	[SKU.CH_PAYOR_BASE_6M_RENEWABLE]: 'Calm Health Payor Base 6 Month Renewable',
	[SKU.CH_PAYOR_BASE_6M_AUTORENEW]: 'Calm Health Payor Base 6 Month Auto-renew',
	[SKU.CH_PAYOR_BASE_1Y]: 'Calm Health Payor Base 1 Year',
	[SKU.CH_PAYOR_BASE_1Y_RENEWABLE]: 'Calm Health Payor Base 1 Year Renewable',
	[SKU.CH_PAYOR_BASE_1Y_AUTORENEW]: 'Calm Health Payor Base 1 Year Auto-renew',
	[SKU.CH_PAYOR_BASE_LIFETIME]: 'Calm Health Payor Base Lifetime',
};

const chPayorBuyupSkuToDisplayNames = {
	[SKU.CH_PAYOR_BUYUP_CUSTOM]: 'Calm Health Payor Buy-up Custom',
	[SKU.CH_PAYOR_BUYUP_1M]: 'Calm Health Payor Buy-up 1 Month',
	[SKU.CH_PAYOR_BUYUP_1M_RENEWABLE]: 'Calm Health Payor Buy-up 1 Month Renewable',
	[SKU.CH_PAYOR_BUYUP_1M_AUTORENEW]: 'Calm Health Payor Buy-up 1 Month Auto-renew',
	[SKU.CH_PAYOR_BUYUP_3M]: 'Calm Health Payor Buy-up 3 Month',
	[SKU.CH_PAYOR_BUYUP_3M_RENEWABLE]: 'Calm Health Payor Buy-up 3 Month Renewable',
	[SKU.CH_PAYOR_BUYUP_3M_AUTORENEW]: 'Calm Health Payor Buy-up 3 Month Auto-renew',
	[SKU.CH_PAYOR_BUYUP_6M]: 'Calm Health Payor Buy-up 6 Month',
	[SKU.CH_PAYOR_BUYUP_6M_RENEWABLE]: 'Calm Health Payor Buy-up 6 Month Renewable',
	[SKU.CH_PAYOR_BUYUP_6M_AUTORENEW]: 'Calm Health Payor Buy-up 6 Month Auto-renew',
	[SKU.CH_PAYOR_BUYUP_1Y]: 'Calm Health Payor Buy-up 1 Year',
	[SKU.CH_PAYOR_BUYUP_1Y_RENEWABLE]: 'Calm Health Payor Buy-up 1 Year Renewable',
	[SKU.CH_PAYOR_BUYUP_1Y_AUTORENEW]: 'Calm Health Payor Buy-up 1 Year Auto-renew',
	[SKU.CH_PAYOR_BUYUP_LIFETIME]: 'Calm Health Payor Buy-up Lifetime',
};

export const chBaseSkuToDisplayNames = {
	[SKU.CH_BASE_CUSTOM]: 'Calm Health Base Custom',
	[SKU.CH_BASE_1M]: 'Calm Health Base 1 Month',
	[SKU.CH_BASE_1M_RENEWABLE]: 'Calm Health Base 1 Month Renewable',
	[SKU.CH_BASE_1M_AUTORENEW]: 'Calm Health Base 1 Month Auto-renew',
	[SKU.CH_BASE_3M]: 'Calm Health Base 3 Month',
	[SKU.CH_BASE_3M_RENEWABLE]: 'Calm Health Base 3 Month Renewable',
	[SKU.CH_BASE_3M_AUTORENEW]: 'Calm Health Base 3 Month Auto-renew',
	[SKU.CH_BASE_6M]: 'Calm Health Base 6 Month',
	[SKU.CH_BASE_6M_RENEWABLE]: 'Calm Health Base 6 Month Renewable',
	[SKU.CH_BASE_6M_AUTORENEW]: 'Calm Health Base 6 Month Auto-renew',
	[SKU.CH_BASE_1Y]: 'Calm Health Base 1 Year',
	[SKU.CH_BASE_1Y_RENEWABLE]: 'Calm Health Base 1 Year Renewable',
	[SKU.CH_BASE_1Y_AUTORENEW]: 'Calm Health Base 1 Year Auto-renew',
	[SKU.CH_BASE_LIFETIME]: 'Calm Health Base Lifetime',
};

const chPremiumSkuToDisplayNames = {
	[SKU.CH_PREMIUM_CUSTOM]: 'Calm Health Premium Custom',
	[SKU.CH_PREMIUM_1M]: 'Calm Health Premium 1 Month',
	[SKU.CH_PREMIUM_1M_RENEWABLE]: 'Calm Health Premium 1 Month Renewable',
	[SKU.CH_PREMIUM_1M_AUTORENEW]: 'Calm Health Premium 1 Month Auto-renew',
	[SKU.CH_PREMIUM_3M]: 'Calm Health Premium 3 Month',
	[SKU.CH_PREMIUM_3M_RENEWABLE]: 'Calm Health Premium 3 Month Renewable',
	[SKU.CH_PREMIUM_3M_AUTORENEW]: 'Calm Health Premium 3 Month Auto-renew',
	[SKU.CH_PREMIUM_6M]: 'Calm Health Premium 6 Month',
	[SKU.CH_PREMIUM_6M_RENEWABLE]: 'Calm Health Premium 6 Month Renewable',
	[SKU.CH_PREMIUM_6M_AUTORENEW]: 'Calm Health Premium 6 Month Auto-renew',
	[SKU.CH_PREMIUM_1Y]: 'Calm Health Premium 1 Year',
	[SKU.CH_PREMIUM_1Y_RENEWABLE]: 'Calm Health Premium 1 Year Renewable',
	[SKU.CH_PREMIUM_1Y_AUTORENEW]: 'Calm Health Premium 1 Year Auto-renew',
	[SKU.CH_PREMIUM_LIFETIME]: 'Calm Health Premium Lifetime',
};

export const SKUToDisplayName = {
	...selfServeSkuToDisplayNames,
	...premiumSkuToDisplayNames,
	...c4bPayorBaseSkuToDisplayNames,
	...c4bPayorBuyupSkuToDisplayNames,
	...chDirectSkuToDisplayNames,
	...chPayorBaseSkuToDisplayNames,
	...chPayorBuyupSkuToDisplayNames,
	...chPremiumSkuToDisplayNames,
};
