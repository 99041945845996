import bowser from 'bowser';

import { EventProps } from '@/hooks/analytics/types';
import { JSONObject } from '@/types/json';
import { Partial } from '@/types/partial';
import { Partner, User } from '@/types/store/reducers';
import AnalyticsAllowList from '@/utils/analytics/allowList';
import { isSelfServePlan } from '@/utils/SkuUtils';

import CalmCookie from '../CalmCookie';

function getSlug(): string {
	const { pathname } = window.location;
	return pathname.replace(/(.+)\/$/, '$1');
}

function getPartnerProps(partner: Partial<Partner> | undefined): JSONObject {
	const csmSegment = (() => {
		if (isSelfServePlan(partner?.vouched_plan_sku)) {
			return 'self-serve';
		}
		if (!partner?.contract_covered_lives) {
			return undefined;
		}
		if (partner.contract_covered_lives <= 300) {
			return 'hvlt';
		}
		if (partner.contract_covered_lives <= 5000) {
			return 'mid-market';
		}
		return 'enterprise / jumbo';
	})();
	const allProps = {
		partner_id: partner?.id,
		partner_name: partner?.name,
		partner_slug: partner?.slug,
		partner_csm_segment: csmSegment,
		partner_category: partner?.category,
	};
	return Object.fromEntries(
		Object.entries(allProps).filter(([, value]) => typeof value !== 'undefined') as [string, string][],
	);
}

function setupEventProps(
	eventProps: EventProps,
	user?: Partial<User>,
	partner?: Partial<Partner>,
): EventProps {
	const browser = bowser.getParser(window?.navigator?.userAgent || '');
	const queryParamProperties = AnalyticsAllowList.getPropertiesFromQueryParams(window.location.search);

	const browserInstance = browser.getBrowser();

	const calmDeviceId = CalmCookie.get('x-device-id');
	const referrer = document.referrer;

	return {
		...queryParamProperties,
		slug: getSlug(),
		...(referrer ? { referrer } : {}),
		...(browserInstance.name ? { browser: browserInstance.name } : {}),
		...(browserInstance.version ? { browser_version: browserInstance.version } : {}),
		...(calmDeviceId ? { calm_device_id: calmDeviceId } : {}),
		is_logged_in_user: Boolean(user?.email),
		service: 'web-partner-portal',
		...(user?.email ? { email: user.email } : {}),
		...getPartnerProps(partner),
		...eventProps,
	};
}

export default setupEventProps;
